import React, { useState, useEffect } from 'react';

const ImageMosaic = ({ images = [] }) => {
  const [optimizedImages, setOptimizedImages] = useState([]);

  useEffect(() => {
    // Function to optimize the image order
    const optimizeImageOrder = (images) => {
      // Sort images by aspect ratio (height/width) for better packing
      return [...images].sort((a, b) => {
        const aspectRatioA = a.height / a.width;
        const aspectRatioB = b.height / b.width;
        return aspectRatioA - aspectRatioB; // Narrower images first
      });
    };

    setOptimizedImages(optimizeImageOrder(images));
  }, [images]);

  const [activeImage, setActiveImage] = useState(null);

  return (
    <div>
      <div className="mosaic-grid">
        {optimizedImages.map((image, index) => {
          const { url, width, height } = image;
          const aspectRatio = (height / width) * 100; // Aspect ratio for CSS padding

          return (
            <div
              key={index}
              className="mosaic-item"
              style={{
                gridColumnEnd: `span ${Math.ceil(width / 10)}`, // Adjust width in grid units
              }}
              onClick={() => setActiveImage(url)}
            >
              <div
                style={{
                  paddingTop: `${aspectRatio}%`, // Intrinsic aspect ratio
                  position: 'relative',
                }}
              >
                <img
                  src={url}
                  alt={`Artwork ${index + 1}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '0px',
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>

      {activeImage && (
        <div className="lightbox" onClick={() => setActiveImage(null)}>
          <div className="lightbox-content">
            <img src={activeImage} alt="Expanded artwork" />
          </div>
        </div>
      )}

      <style jsx>{`
        .mosaic-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
          gap: 10px;
        }

        .mosaic-item {
          align-items: center; /* Center vertically */
          position: relative;
          overflow: hidden;
          cursor: pointer;
        }

        .mosaic-item img {
          max-width: 100%; /* Ensure the image scales within the container */
          max-height: 100%; /* Ensure the image scales within the container */
          object-fit: contain; /* Maintain aspect ratio */
          transition: transform 0.3s ease;
        }

        .mosaic-item:hover img {
          transform: scale(1.05);
        }

        .lightbox {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        
        .lightbox-content {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 90vh; /* Allow up to 90% of viewport height */
          max-width: 90vw; /* Allow up to 90% of viewport width */
        }
        
        .lightbox img {
          max-height: 90vh; /* Constrain height to 90% of viewport */
          max-width: 90vw; /* Constrain width to 90% of viewport */
          object-fit: contain; /* Ensure the image scales proportionally */
          border-radius: 8px; /* Optional: Add rounded corners for style */
        }
      `}</style>
    </div>
  );
};

export default ImageMosaic;
