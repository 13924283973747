import '../App.css';
import ImageCarousel from '../ImageCarousel.js';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function Onda({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    const appImages = [
        'https://media.louisromier.com/Onda/homeScreen.PNG',
        'https://media.louisromier.com/Onda/searchScreen.PNG',
        'https://media.louisromier.com/Onda/listingsScreen.PNG',
        'https://media.louisromier.com/Onda/listingInfoScreen.PNG',
        'https://media.louisromier.com/Onda/checkoutScreen.PNG',
        'https://media.louisromier.com/Onda/boardScreen.PNG',
        'https://media.louisromier.com/Onda/addBoardScreen.PNG'
    ];

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">{title[language]}</h2>
                <h3 className="date">{date}</h3>
            </div>
            <p className='text'>Drove the inception, development, and market launch of a pioneering peer-to-peer surfboard rental platform, blending technical expertise with strategic marketing to enhance user acquisition and engagement.</p>
            <ul>
                <li className="text">
                    Engineered and launched a user-friendly application in React Native, achieving approval on the Apple App Store within four months.
                </li>
                <li className="text">
                    Forged strategic partnerships with local surf shops and online communities to drive platform adoption through targeted grassroots marketing.
                </li>
                <li className="text">
                    Developed comprehensive website content, including terms of service and privacy policies, ensuring legal compliance and user trust.
                </li>
                <li className="text">
                    Successfully scaled the platform to 1,000 user accounts, with over 250 surfboards listed and approximately 50 rentals executed.
                </li>
            </ul>
            <h2 className="title">Onda Screenshots</h2>
            <ImageCarousel imageUrls={appImages} />
        </div>
    );
}

export default Onda;
