import '../App.css';
import ImageMosaic from '../ImageMosaic.js';

function Art({ experiences }) {
  const exp = experiences["Art"];

  const artImages = [
    { url: 'https://media.louisromier.com/Art/Parque.jpg', width: 24, height: 19 },
    { url: 'https://media.louisromier.com/Art/BaseOnTopOfTheWorld.jpg', width: 12, height: 9 },
    { url: 'https://media.louisromier.com/Art/DaturasPerformingforJ-Trees.jpg', width: 9, height: 12 },
    { url: 'https://media.louisromier.com/Art/Madonna.jpg', width: 24, height: 19 },
    { url: 'https://media.louisromier.com/Art/Vallon-Pont-dArc.jpg', width: 24, height: 24 },
    { url: 'https://media.louisromier.com/Art/SeashoreSeashells.jpg', width: 24, height: 19 },
    { url: 'https://media.louisromier.com/Art/LesTroisPetitsCochons.jpg', width: 18, height: 12 },
    { url: 'https://media.louisromier.com/Art/CawingCrows.jpg', width: 12, height: 9 },
    { url: 'https://media.louisromier.com/Art/Venus.jpg', width: 24, height: 24 },
    { url: 'https://media.louisromier.com/Art/LoneLily.jpg', width: 9, height: 12 },
    { url: 'https://media.louisromier.com/Art/SunflowerBoogie copy.JPG', width: 9, height: 12 },
    { url: 'https://media.louisromier.com/Art/Marionette.jpg', width: 24, height: 48 },
    { url: 'https://media.louisromier.com/Art/Intersections.jpg', width: 11.5, height: 42 },
    { url: 'https://media.louisromier.com/Art/RedStripe.jpg', width: 12, height: 18 },
    { url: 'https://media.louisromier.com/Art/MatchPoint.jpeg', width: 24, height: 18 },
    { url: 'https://media.louisromier.com/Art/Petanque.jpeg', width: 12, height: 18 },
    { url: 'https://media.louisromier.com/Art/BreakOnThrough.jpeg', width: 42, height: 24 },
    { url: 'https://media.louisromier.com/Art/ToDoro.jpeg', width: 32, height: 24 },
    { url: 'https://media.louisromier.com/Art/JTreeDisco.jpeg', width: 18, height: 12 },
  ];

  return (
    <div className="experience-container art-container">
      <div className="titleRow">
        <h2 className="title">Art</h2>
        <h3 className="date">Present</h3>
      </div>
      <p className="text">Some Oil, Acrylic, and Pastel works.</p>
      <div className="gallery">
        <ImageMosaic images={artImages} />
      </div>
    </div>
  );
}

export default Art;