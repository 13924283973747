import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function MachineLearning({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    const testImage = 'https://media.louisromier.com/Misc/test.png'
    const histogramImage = 'https://media.louisromier.com/Misc/DataHistogramV2.png'

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h1 className="title">{title[language]}</h1>
                <h3 className="date">{date}</h3>
            </div>
            <p className='text'>
                Harnessing the potent blend of machine learning and the thrilling world of surfing, this project aspires to reinvent how surfer performance is judged during competitions. Unlike traditional methods, where human bias might interfere, this system leverages the computational power and precision of TensorFlow to offer unbiased evaluations.
            </p>
            <p className='text'>
                Recognizing the significance of consistent data for any machine learning task, I turned to the Surfranch event. This venue presents the rare luxury of having almost identical waves, one after the other, making it the ideal data source for our model's foundation. Python scripts efficiently wrangled, sorted, and organized the collected videos into their respective validation, test, and training datasets.
            </p>
            <p className='text'>
                Navigating the intricacies of 3D+1 convolutional neural networks, extensive research went into crafting the model with the right layers and configurations tailored for this unique application. As of now, the iterative process of tuning the model is in motion, aimed at enhancing its predictive accuracy. While early results are promising, a potential revisit to the data collection phase is under consideration to further fortify the model's capabilities.
            </p>
            <p className='text'>
                The Surfer Performance Evaluator epitomizes the fusion of sports and tech, showcasing how artificial intelligence can bring transformative change even to the most traditional arenas.
            </p>
            <div className="image-section">
                <h2>Model Evaluation</h2>
                <img src={testImage} alt="Predicted vs Actual Scores" className="result-image" />
                <p className="caption">
                    Predicted vs Actual Scores showcasing model performance.
                </p>
                <img src={histogramImage} alt="Data Distribution in Train, Test, and Validation Sets" className="result-image" />
                <p className="caption">
                    Distribution of data across train, test, and validation sets.
                </p>
            </div>
        </div >
    );
}

export default MachineLearning;