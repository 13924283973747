import '../App.css';

function ETQ({ experiences }) {
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">Senior Implementation Consultant at ETQ</h2>
                <h3 className="date">Dec 2024 - Present</h3>
            </div>
            <div className="titleRow">
                <h2 className="title">Implementation Consultant at ETQ</h2>
                <h3 className="date">Feb 2023 - Dec 2024</h3>
            </div>
            <p className='text'>Specializing in customizing SaaS solutions for diverse client needs through advanced API integrations and system design.</p>
            <ul>
                <li className="text">Designed and implemented tailored solutions based on detailed customer requirements, optimizing their operational workflows through strategic API integrations with third-party systems. </li>
                <li className="text">Conducted technical demos and communicated complex product functionalities to stakeholders across technical and non-technical backgrounds, ensuring clear understanding and alignment. </li>
                <li className="text">Played a pivotal role in project planning, aligning with product and engineering teams to understand and incorporate future product enhancements in customer solutions. </li>
                <li className="text">Expertly managed customer expectations around product capabilities and planned updates, facilitating smooth transitions and sustained customer satisfaction. </li>
            </ul>
            <div className="titleRow">
                <h2 className="title">Technical Implementation Consultant at ETQ</h2>
                <h3 class="date">Nov 2021 - Feb 2023</h3>
            </div>
            <ul>
                <li className="text">Gained expert proficiency in ETQ’s software and proprietary scripting languages, utilizing SQL to architect and deploy robust configurations tailored to diverse client needs. </li>
                <li className="text">Streamlined communication of complex technical specifications across a broad spectrum of clients, from Fortune 500 companies to small enterprises, ensuring clear understanding and alignment.</li>
                <li className="text">Conducted extensive user training sessions for hundreds of clients, consistently achieving an impressive 4.5/5 average satisfaction rating, underscoring my ability to educate and engage users effectively.</li>
            </ul>
        </div>
    );
}


export default ETQ;
