import '../App.css';
import { useContext } from 'react';
import LanguageContext from '../LanguageContext';

function PlatformConsultant({ experiences }) {
    const { language } = useContext(LanguageContext);
    const { title, date, keywords, content } = experiences;

    return (
        <div className="experience-container">
            <div className="titleRow">
                <h2 className="title">{title[language]}</h2>
                <h3 className="date">{date}</h3>
            </div>
            <p className='text'>Led the design and research for a strategic platform initiative, focusing on architecture management and network effects, driving toward successful market introduction.</p>
            <ul>
                <li className="text">
                    Developed a strategic 50-page recommendation on launching a new platform, focusing on governance, scalability, and market entry barriers.
                </li>
                <li className="text">
                    Designed a multi-phase expansion strategy ensuring sustained adoption and scalability prior to introducing additional market layers.
                </li>
            </ul>
        </div>
    );
}

export default PlatformConsultant;